import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import VideoPlayerModal from './videoPlayerModal';
import VideoActionAreaCard from './videoGalleryCards';
import axios from 'axios';
import { useEffect, useState } from 'react';

import rightImage from '../assets/Search Icon.svg';
import cardImageOne from '../assets/Screenshot from 2024-11-07 11-06-40.png';
import cardImageTwo from '../assets/Screenshot from 2024-11-07 09-27-00.png';

import { useTheme, useMediaQuery } from '@mui/material'; 


const style = {
  position: 'absolute',
  top: '50%',
  left: { xs: '46.4%', sm: '47%', md: '50%' },
  transform: 'translate(-50%, -50%)',
  width: { xs: '70%', sm: '80%', md: '80%' },
  height: { xs: '90vh', sm: 'auto' },
  bgcolor: 'rgba(191, 184, 155)',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: 3,
  overflow: 'hidden',
};

const closeIconContainerStyle = {
  position: 'absolute',
  top: {xs: -18, sm: 16, md: 16},
  right: {xs:-18, sm: 0, md: 0 },
  backgroundColor: '#555',
  borderRadius: '50%',
  padding: '2px',
  cursor: 'pointer',
  color: 'white',
  width: '25px',
  height: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 20,  
};


const closeIconStyle = {
  color: 'white',
  fontSize: '24px',
};

const headerStyle = {
  position: 'relative',
  width: '100%',
  zIndex: 10,
  textAlign: 'center',
  paddingBottom: 2,
  display: 'flex',
  justifyContent: 'space-between',  
  alignItems: 'center', 
  
};

const scrollableContentStyle = {
  display: 'grid',
  // gridTemplateColumns: { xs: '1fr', sm: 'repeat(4, 1fr)' },
  gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
  gap: 2,
  paddingTop: { xs: '50px', sm: '50px' },
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: { sm: '50vh' },
  overflowY: 'auto',
  marginX: 'auto',
  paddingBottom: '100px',
  marginLeft: {xs: '4px', sm: '20px', md: '40px'},
};

export default function GalleryModal({ open, onClose }) {

  const [galleryVideos, setGalleryVideos] = useState([]);

  useEffect(() => {
    getVideoData(); 
  }, []);

  const getVideoData = async () => {
    try {
      const response = await axios.get("/media/videos/");
      console.log("videos data:", response.data);
  
      // Process the videos data
      const processedVideos = response.data.map(video => ({
        id: video.id,
        title: video.title,
        description: video.description,
        file: video.file.startsWith('http') 
          ? video.file.replace('/videos/videos', '/videos') 
          : `/${video.file.replace('/videos/videos', '/videos')}`,
        uploadedAt: video.uploaded_at,
        image_url: video.image ? `/media/${video.image}` : null,  //i concatenated with base url for image
      }));
  
      console.log("processed VIDS:", processedVideos);
      setGalleryVideos(processedVideos);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };
  
  
  const [openVideoModal, setOpenVideoModal] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState('');
  const [modalTitle, setModalTitle] = React.useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getHeaderFontWeight = () => (isMobile ? 'normal' : 'bold');

  const handleCardClick = (videoSrc, videoTitle) => {
    console.log("Video Clicked:", videoTitle, videoSrc); 
    if (videoSrc && videoTitle) {
      setSelectedVideo({ src: videoSrc, title: videoTitle });
      setOpenVideoModal(true);
    } else {
      console.error("Video source or title is missing", { videoSrc, videoTitle });
    }
  };
  

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };


  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box sx={headerStyle}>
          <Typography sx={{ color: 'black', fontSize: '1.3rem', fontWeight: 'bold', fontWeight: getHeaderFontWeight() }}>
            30 Years Of Freedom And Democracy Videos
          </Typography>

          <Box sx={closeIconContainerStyle} onClick={onClose}>
            <CloseIcon sx={closeIconStyle} />
          </Box>

          {/* Add search input to the right of the close icon for desktop */}
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' }, 
              alignItems: 'center',
              zIndex: 10,
            }}
          >
            <TextField
              sx={{
                marginRight: '100px',
                backgroundColor: 'white',
                width: 250,
                borderRadius: 7,
                '& .MuiInputBase-root': { fontSize: '0.875rem' },
                '& .MuiOutlinedInput-root': { border: 'none' },
                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
              }}
              placeholder="Search Event / Date"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={rightImage} alt="Search Icon" style={{ width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Box sx={scrollableContentStyle}>
          
        {galleryVideos.map((galleryVideo, index) => (
  <Box key={index} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: '8px' }}>
    <VideoActionAreaCard
      image={galleryVideo.image_url || cardImageOne}
      title={galleryVideo.title}
      description={galleryVideo.description}
      onCardClick={() => {
        console.log("Card clicked for video", galleryVideo); 
        handleCardClick(galleryVideo.file, galleryVideo.title); 
      }}
    />
  </Box>
))}
        </Box>
        {openVideoModal && (
         <VideoPlayerModal 
          open={openVideoModal} 
          onClose={handleCloseVideoModal} 
          title={selectedVideo.title} 
          videoSrc={selectedVideo.src} 
        />
        )}
      </Box>
    </Modal>
  );
}
