import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  // position: 'absolute',
  top: '50%',
  left: '47%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  maxWidth: 600,
  // bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 8,
  bgcolor: 'rgba(191, 184, 155)',
  position: 'relative', 
};

const closeIconContainerStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  backgroundColor: '#555',
  borderRadius: '50%',
  padding: '2px', 
  cursor: 'pointer',
  color: 'white',
  width: '25px', 
  height: '25px', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
  
export default function VideoPlayerModal({ open, onClose, title, videoSrc }) {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box sx={closeIconContainerStyle} onClick={onClose}>
          <CloseIcon />
        </Box>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          {title}
        </Typography>
        <Box component="video" src={videoSrc} controls autoPlay style={{ width: '100%' }} />
      </Box>
    </Modal>
  );
}
