import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CustomButton from './customButton';
import VideoActionAreaCard from './videoGalleryCards';
import VideoCardsModal from './videoCardsModal'; 
import VideoPlayerModal from './videoPlayerModal';
import cardImageOne from '../assets/Screenshot from 2024-11-07 11-06-40.png';
import cardImageTwo from '../assets/Screenshot from 2024-11-07 09-27-00.png';
import cardImageThree from '../assets/Screenshot from 2024-11-07 09-27-23.png';
import { useTheme, useMediaQuery } from '@mui/material'; 
import { useEffect } from 'react';
import axios from 'axios';

export default function BannerWithVideoCards({ titleText }) {

  const [galleryVideos, setGalleryVideos] = useState([]);

  useEffect(() => {
    getVideoData(); 
  }, []);

  const getVideoData = async () => {
    try {
      const response = await axios.get("/media/videos/");
      console.log("videos data:", response.data);
  
      // Process the videos data
      const processedVideos = response.data.map(video => ({
        id: video.id,
        title: video.title,
        description: video.description,
        file: video.file.startsWith('http') 
          ? video.file.replace('/videos/videos', '/videos') 
          : `/${video.file.replace('/videos/videos', '/videos')}`,
        uploadedAt: video.uploaded_at,
        image_url: video.image ? `/media/${video.image}` : null,  //i concatenated with base url for image
      }));
  
      console.log("processed VIDS:", processedVideos);
      setGalleryVideos(processedVideos);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };
  
  
  
  
  const theme = useTheme(); 
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({ src: '', title: '' });

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleCardClick = (videoSrc, videoTitle) => {
    console.log("Video Clicked:", videoTitle, videoSrc);
    setSelectedVideo({ src: videoSrc, title: videoTitle });
    setIsVideoModalOpen(true);
  };

  const handleCloseVideoModal = () => setIsVideoModalOpen(false);

  return (
    <Box sx={{ py: 4 }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, 
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
          }} id="videoGallery"
        >
          <Typography
            variant={isMobile ? 'h6' : 'h4'}
            sx={{
              fontWeight: 'bold',
              order: { xs: 1, sm: 0 }, 
              textAlign: { xs: 'center', sm: 'left' }, 
            }}
          >
            {titleText}
          </Typography>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <CustomButton text="View All Video's" onClick={handleOpenModal} />
          </Box>
        </Box>

        {/* Cards Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, 
            justifyContent: 'center', 
            gap: 2,
            alignItems: 'center', 
          }}
        >
          {galleryVideos.map((video) => (
    <VideoActionAreaCard
      key={video.id}
      image={video.image_url || cardImageOne} 
      title={video.title}
      description={video.description}
      onCardClick={() => handleCardClick(video.file, video.title)} 
    />
  ))}
        </Box>

        
        {/*button below cards only for mobile responsiveness */}
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none' }, // so now this will only display in mobile mode
            justifyContent: 'center', 
            mt: 2,
          }}
        >
          <CustomButton text="View All Video's" onClick={handleOpenModal} />
        </Box>

        {/*main modal component for video cards */}
        <VideoCardsModal open={isModalOpen} onClose={handleCloseModal} onCardClick={handleCardClick} />

        {/*video player modal */}
        <VideoPlayerModal 
          open={isVideoModalOpen} 
          onClose={handleCloseVideoModal} 
          title={selectedVideo.title} 
          videoSrc={selectedVideo.src} 
        />
      </Container>
    </Box>
  );
}
