import React from 'react';
import Button from '@mui/material/Button';

function CustomButton({ 
  text, 
  onClick, 
  navigateToUrl = false,  // Add a default value here
  url = '/bus-map/'  // Optional URL prop with default
}) {
  const handleClick = () => {
    // If navigateToUrl is true, open the URL
    if (navigateToUrl) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }

    // If you also want to keep any existing onClick functionality
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#FF5733', 
        color: '#fff',
        fontWeight: 'bold',
        borderRadius: '8px',
        padding: '10px 20px',
        '&:hover': {
          backgroundColor: '#C14B27', 
        },
      }}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
}

export default CustomButton;