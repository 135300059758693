import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'; 
// import PlayArrowIcon from '@mui/icons-material/PlayArrow'; 

export default function ArrowActionAreaGalleryCard({ image, title, description, onCardClick }) {
  return (
    <Card sx={{ maxWidth: 345, width: 300, position: 'relative' }} onClick={onCardClick}> 
      {/* arrow icon pointing to the top-right */}
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: {xs: 35, sm: 16, md: 24},
          backgroundColor: 'blue',
          color: 'white',
          width: 32,
          height: 32,
          borderRadius: '50%',
          zIndex: 10,
          '&:hover': {
            backgroundColor: 'darkblue',
          },
        }}
      >
        <ArrowUpwardIcon sx={{ transform: 'rotate(45deg)', fontSize: 24 }} /> 
      </IconButton>

      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt={title}
        />
        <CardContent
          sx={{
            backgroundColor: 'rgba(12, 59, 148)',
            height: 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start', 
            gap: 0.5, 
            padding: 1.5, 
          }}
        >
          <Typography gutterBottom variant="h6" component="div" sx={{ color: 'white', fontSize: '1rem', marginBottom: 0.5 }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'white', fontSize: '0.875rem' }}>
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
