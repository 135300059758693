import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close'; 

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',  // default width for larger screens
  maxHeight: '80vh',
  bgcolor: 'rgba(191, 184, 155)',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',  // for scrolling if needed
  borderRadius: 8,
  '@media (max-width:600px)': {  //for mobile screens
    width: '65%',  // narrower width on mobile
  }
};

const closeIconContainerStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  backgroundColor: '#555',
  borderRadius: '50%',
  padding: '2px', 
  cursor: 'pointer',
  color: 'white',
  width: '25px', 
  height: '25px', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const closeIconStyle = {
  color: 'white',
  fontSize: '24px',
};

export default function ImagesOnlyModal({ open, onClose, imageSet, title }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* close button */}
        <Box sx={closeIconContainerStyle} onClick={onClose}>
          <CloseIcon sx={closeIconStyle} />
        </Box>

        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
          {title}
        </Typography>
        
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',  // default layout with 4 columns
            gap: 2,
            paddingTop: '20px',
            '@media (max-width:600px)': {  //for mobile screens
              gridTemplateColumns: '1fr',  // stack cards vertically on mobile
            }
          }}
        >
          {imageSet.map((image, index) => (
            <img key={index} src={image} alt={`Event Image ${index + 1}`} style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
          ))}
        </Box>
      </Box>
    </Modal>
  );
}
