import React, { useState } from "react";
import { Button } from "@mui/material";
import EventSelectedModal from "./calendarSelectedEventModal";
import axios from "axios";
import { useEffect } from "react";


//this is a function to generate a simple calendar
const generateCalendar = (month, year) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const startDay = new Date(year, month, 1).getDay(); // starting day of the month
  const districtMetro = "";

  const calendar = [];
  let week = [];

  //now create the weeks in the calendar
  for (let i = 0; i < startDay; i++) {
    week.push(null); //empty cells for days before the first day of the month
  }

  for (let day = 1; day <= daysInMonth; day++) {
    week.push(day);

    if (week.length === 7 || day === daysInMonth) {
      calendar.push(week);
      week = [];
    }
  }

  return calendar;
};

const Calendar = () => {
  const [emails, setEmails] = useState([]);  //was never here
  const [selectedEvent, setSelectedEvent] = useState({}); 
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const calendar = generateCalendar(currentMonth, currentYear);

  // useEffect(() => {
  //   axios
  //     .get("http://127.0.0.1:8000/events-calendar/")
  //     .then((response) => {
  //       const fetchedEmails = response.data.map((event) => event.email);
  //       setEmails(fetchedEmails); 
  //       console.log("Fetched data:", response.data);  
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching events:", error);
  //     });
  // }, []);

  useEffect(() => {
    console.log("HELLO WORLD");
     getData();
  }
  , []);

  const getData = async() => {
    // axios
    //   .get("http://127.0.0.1:8000/events-calendar/")
    //   .then((response) => {
    //     // const fetchedEmails = response.data.map((event) => event.email);
    //     // setEmails(fetchedEmails); 
    //     console.log("Fetched data:", response.data);  
    //     setEmails(response.data);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching events:", error);
    //   });
    try
    {
    const response = await axios.get("/events-calendar/");
    setEmails(response.data);
} 
catch (error) {
    console.error("Error fetching events:", error);
  }}
console.log(emails);


  
  const hardcodedDay = 20;
  const hardcodedMonth = "November";
  const hardcodedMonthIndex = new Date(Date.parse(`${hardcodedMonth} 1, 2023`)).getMonth();
  const harcodedName = "Johan";

  const activity = "";
  const districtMetro = "";
  const localMunisipality = "";
  const venue = "";
  const stakeHolders = "";
  const college = "";
  const mictSeta = "";
  const merseta = "";
  const nyda = "";
  const name = "";
  const tel = "";
  const email = "";
  
  const handleTodayClick = () => {
    const today = new Date();
    setCurrentMonth(today.getMonth());
    setCurrentYear(today.getFullYear());
    setSelectedDate(`${today.getDate()} ${today.toLocaleString("default", { month: "long" })}`);
  };

  const handleMonthClick = () => {
    setIsModalOpen(true)    // I just need to add some logic for month-specific actions (coming soon)
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    };

  const handleClick = (day) => {
    if (day) {
      setSelectedDate(`${day} ${new Date(currentYear, currentMonth).toLocaleString("default", { month: "long" })}`);
    }
  };

  const goToPreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear((prevYear) => prevYear - 1);
    } else {
      setCurrentMonth((prevMonth) => prevMonth - 1);
    }
  };

  const goToNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear((prevYear) => prevYear + 1);
    } else {
      setCurrentMonth((prevMonth) => prevMonth + 1);
    }
  };

  return (
    <div style={{ padding: "10px", backgroundColor: "white", borderRadius: "8px", maxWidth: "580px", margin: "auto" }}>
      {/*month navigation */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
        {/*grouping all the buttons together */}
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {/*previous and next month buttons */}
          <button onClick={goToPreviousMonth} style={{ fontSize: "0.5rem", padding: "5px 10px" }}>&lt;</button>
          <button onClick={goToNextMonth} style={{ fontSize: "0.5rem", padding: "5px 10px" }}>&gt;</button>

          {/*month button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleMonthClick}
            sx={{
              textTransform: "none",
              backgroundColor: "white",
              color: "black",
              '&:hover': {
                backgroundColor: "lightgray"
              },
              fontSize: "0.7rem", 
              padding: "5px 10px",
            }}
          >
            Month
          </Button>

          {/*today button */}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleTodayClick}
            sx={{
              textTransform: "none",
              backgroundColor: "white",
              color: "black",
              '&:hover': {
                backgroundColor: "lightgray"
              },
              fontSize: "0.7rem", 
              padding: "5px 10px",
            }}
          >
            Today
          </Button>
        </div>
        <h1
          style={{
            fontSize: "0.7rem", 
            fontWeight: "normal",
            marginBottom: "0.5rem",
            marginLeft: "7px",
          }}
        >
          {new Date(currentYear, currentMonth).toLocaleString("default", { month: "long" })} {currentYear}
        </h1>
      </div>

      {/*calendar Table */}
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ fontSize: "0.7rem", padding: "6px" }}>Sun</th>
            <th style={{ fontSize: "0.7rem", padding: "6px" }}>Mon</th>
            <th style={{ fontSize: "0.7rem", padding: "6px" }}>Tue</th>
            <th style={{ fontSize: "0.7rem", padding: "6px" }}>Wed</th>
            <th style={{ fontSize: "0.7rem", padding: "6px" }}>Thu</th>
            <th style={{ fontSize: "0.7rem", padding: "6px" }}>Fri</th>
            <th style={{ fontSize: "0.7rem", padding: "6px" }}>Sat</th>
          </tr>
        </thead>
        <tbody>
          {calendar.map((week, weekIndex) => (
            <tr key={weekIndex}>
              {week.map((day, dayIndex) => (
                <td
                  key={dayIndex}
                  className={
                    day === parseInt(selectedDate?.split(" ")[0]) &&
                    currentMonth === new Date(selectedDate).getMonth() &&
                    currentYear === new Date(selectedDate).getFullYear()
                      ? "highlight"
                      : ""
                  }
                  onClick={() => handleClick(day)}
                  style={{
                    cursor: day ? "pointer" : "default",
                    padding: "6px",
                    fontSize: "0.7rem", 
                  }}
                >
                  {day}
                  {/* this part was replaced */}
                  {/* {day === hardcodedDay &&
  currentMonth === hardcodedMonthIndex &&
  currentYear === new Date().getFullYear() && (
    <a
      className="custom-text"
      onClick={(e) => {
        e.preventDefault();
        // console.log(`Clicked on ${harcodedName}`);
        setIsModalOpen(true); 
      }}
    > */}
      {/* {emails[0]} testing display the first email */}

      {emails?.map((email) => 
        {

// Convert to a Date object
const date = new Date(email.start_date);

// Extract the year, month, and day
const year = date.getFullYear();
const month = date.getMonth(); // Months are 0-indexed in JavaScript
const currentDay = date.getDate();
console.log(year, month, currentDay, currentYear, currentMonth, day);
          if (currentDay === day && month === currentMonth && currentYear === year) {
            return (
              <a
                className="custom-text"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedEvent(email);
                  setIsModalOpen(true);
                }}
              >
                {email.name}
              </a>
            );
          }
}
      // </a>
)}

                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/*selected date */}
      {/* {selectedDate && (
        <div>
          <p style={{ fontSize: "0.7rem" }}>You selected: {selectedDate}</p>
        </div>
      )} */}

      <EventSelectedModal open={isModalOpen} handleClose={handleModalClose} selectedEvent={selectedEvent} /> {/*add additional data as prop */}

      <style jsx>{`
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th,
        td {
          padding: 10px;
          text-align: center;
          border: 1px solid #ddd;
          vertical-align: top;
          width: 14.28%; /* 100 / 7 */
        }
        td.highlight {
          background-color: yellow;
        }
        td:hover {
          background-color: lightgray;
        }
        .custom-text {
         font-size: 10px;
         color: blue;
         font-weight: bold;
         display: block; 
         margin-top: 10px; 
}


        @media (max-width: 600px) {
          h1 {
            font-size: 1rem;
            margin-bottom: 0.5rem;
          }

          button {
            font-size: 0.8rem;
            padding: 8px 10px;
          }

          .custom-text {
            font-size: 10px;
          }

          td {
            padding: 6px;
            font-size: 0.7rem;
          }

          p {
            font-size: 0.7rem;
          }

        }

        @media (min-width: 601px) {
          td {
            height: 105px; /* increase height for desktop */
          }
        }

        @media (max-width: 501px) {
          td {
            height: 57px;  /* was 37px*/
          }
        }
      `}</style>
    </div>
  );
};

export default Calendar;
