import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';

export default function ActionAreaCard({ image, title, description }) {
    return (
      <Card sx={{ maxWidth: 345, width: 345 }}> 
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={image} 
            alt={title}  
          />
          <CardContent
            sx={{
                backgroundColor: 'rgba(12, 59, 148)', 
            }}
          >
            <Typography 
              gutterBottom 
              variant="h5" 
              component="div"
              sx={{ 
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis',
                color: 'white' 
              }}
            >
              {title}  
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                // color: 'text.secondary',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: 'white'
              }}
            >
              {description}  
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }