import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CustomButton from './customButton';
import BasicModal from './calendarModal'; 
import { useTheme, useMediaQuery } from '@mui/material'; 

function RightImageBanner({ titleText2, leftImageSrc2, rightText2 }) {
  const theme = useTheme(); 
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
  const [open, setOpen] = React.useState(false);

  //this is for the modal
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <Box sx={{  py: 4 }} id="calendar">
      <Container maxWidth="lg">
        {/* top centered bold title */}
        <Typography 
         variant={isMobile ? 'h6' : 'h4'}
         sx={{ fontWeight: isMobile ? 'bold' : 500,  textAlign: 'center', mb: 3 }}>
          {titleText2}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row-reverse' }, 
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          {/* right image */}
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <img
              src={leftImageSrc2}
              alt="Banner right"
              style={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '8px',
              }}
            />
          </Box>

          {/* left text and button */}
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start', textAlign: 'center', ml: { sm: '10px', xs: 0 }  }}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 100, mb: 2, fontSize: '1.5rem' }}>
                {rightText2}
              </Typography>
              <CustomButton text="View Event Calendar" onClick={handleOpenModal} />
            </Box>
          </Box>
        </Box>
      </Container>

      {/* this will open the modal for the calendar */}
      <BasicModal open={open} onClose={handleCloseModal} />
    </Box>
  );
}

export default RightImageBanner;
