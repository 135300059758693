import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Calendar from './calendarComponentTest';

const BasicModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="event-calendar-modal"
      aria-describedby="event-calendar-modal-description"
    >
      <Box sx={modalStyle}>
        {/*close Button */}
        <IconButton
  onClick={onClose}
  sx={{
    position: 'absolute',
    top: 7, 
    right: 10,
    color: 'white',
    backgroundColor: '#555',
    width: '27px',
    height: '27px',
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center', 
    padding: 0, 
    '@media (max-width: 600px)': {
      display: 'block',
      width: '25px', 
      height: '25px',
      top: 7,
      marginTop: '0', //resets any unwanted margin for mobile
    },
  }}
>
  <CloseIcon
    sx={{
      fontSize: '18px', 
      position: 'relative',
      top: '-1px', //this is to move X up slightly within the button
      '@media (max-width: 600px)': {
        fontSize: '20px', //make it slightly larger icon for mobile
      },
    }}
  />
</IconButton>


        {/*i had to add margin to push the calendar down */}
        <div style={{ marginTop: '30px' }}>
          <Calendar />
        </div>
      </Box>
    </Modal>
  );
};

//the entire modal style with responsive design
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'rgba(191, 184, 155)',
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
  maxWidth: 600,
  width: '90%',
  maxHeight: 700,
  height: '90%',
  overflowY: 'auto',
  '@media (max-width: 600px)': {
    width: '80%',
    maxWidth: '400px',
    padding: '16px',
    height: '47%',
    maxHeight: '600px',
    marginLeft: '-15px',
  },
  '@media (max-width: 400px)': {
    width: '90%',
    maxWidth: '300px',
    padding: '12px',
  },
};

export default BasicModal;
