import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme, useMediaQuery } from '@mui/material';

function Banner({ leftImageSrc, rightImageSrc, topText, bottomText }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: 2,
        borderRadius: 2,
        overflow: 'hidden',
        marginTop: isMobile ? '-70px' : 0, 
      }} 
      id="home"
    >
      {isMobile ? (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
              gap: 2,
            }}
          >
            <img
              src={leftImageSrc}
              alt="Left Top"
              style={{
                width: isMobile ? '200px' : 0,
                height: 'auto',
                marginTop: '100px',
              }}
            />
            <img
              src={rightImageSrc}
              alt="Right"
              style={{
                width: isMobile ? '200px' : 0,
                height: 'auto',
                marginTop: '100px',
              }}
            />
          </Box>

          {/* Centered text below images */}
          <Box sx={{ textAlign: 'center', marginTop: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, marginLeft: {xs: '-20px', sm: '0px'}}}>
              {topText}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, fontSize: '1rem' }}
              dangerouslySetInnerHTML={{ __html: bottomText }} // rendering html inside the text added this for line break for bottom text
            />
          </Box>
        </>
      ) : (
        // desktop view (original layout)
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', flex: 1 }}>
            <img src={leftImageSrc} alt="Left Top" style={{ width: '400px', marginBottom: '20px' }} />
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }} >
              {topText}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, fontSize: '1.5rem', marginBottom: '-100px' }}
              dangerouslySetInnerHTML={{ __html: bottomText }} // rendering html inside the text added this for line break for bottom text
            />
          </Box>

          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <img
              src={rightImageSrc}
              alt="Right"
              style={{
                width: '80%',
                height: 'auto',
                marginTop: isTablet ? '-250px' : 100,   //this was awlways margin top 100px
                position: 'relative',
                left: '-10px',
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default Banner;
