import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';

import sanralLogo from '../assets/Desktop Bottom Nav Design.png';  
import thirtyYearsLogo from '../assets/White 30 Years Logo .svg';

const pages = [
  { name: 'Home', id: 'home' },
  { name: 'Journey Map', id: 'journeyMap' },
  { name: 'Calendar', id: 'calendar' },
  { name: 'Gallery', id: 'gallery' },
  // { name: 'Media Releases', id: 'media_releases' },
  // {name: 'Video Gallery', id: 'videoGallery'},
  { name: 'Contact Us' }
];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSmoothScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <AppBar position="sticky" sx={{ position: 'sticky', overflow: 'hidden', backgroundColor: '#f5f5f5' }}>
      <Box 
        sx={{
          position: 'absolute', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          zIndex: -0,
          width: { xs: '100%', sm: '100%', md: '50%' },  //sm here was 100px NOT 100% like it is now
        }}
      >
        <img 
          src={sanralLogo} 
          alt="Background Logo" 
          style={{ 
            width: '100%', 
            height: 'auto', 
            objectFit: 'cover',  
            maxWidth: '100%',
            width: { xs: '120%', sm: '100%', md: '70%' },
          }} 
        />
      </Box>

      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/*desktop logo */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <img 
              src={thirtyYearsLogo} 
              alt="30 Years Logo" 
              style={{ height: 40, width: 'auto', marginLeft: '-100px' }} 
            />
          </Box>

          {/* Mobile hamburger menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, alignItems: 'center'}}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                marginLeft: '-15px',
                '& .MuiPaper-root': {
                  backgroundColor: 'rgba(191, 184, 155)',
                  color: 'black', 
                },
              }}
            >
              {pages.map((page) => (
                <MenuItem 
                  key={page.name} 
                  onClick={() => {
                    handleSmoothScroll(page.id); 
                    handleCloseNavMenu();
                  }}
                >
                  <Typography sx={{ textAlign: 'center' }}>{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Box sx={{ ml: 'auto' }}>
              <img src={thirtyYearsLogo} alt="30 Years Logo" style={{ height: 40, width: 'auto' }} />
            </Box>
          </Box>

          {/*desktop Menu Items */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', gap: 2 }}>
            {pages.map((page) => (
              <Typography
                component="a"
                key={page.name}
                onClick={() => {
                  handleSmoothScroll(page.id);  
                  handleCloseNavMenu();  
                }}
                style={{
                  my: 2,
                  color: 'black',
                  display: 'block',
                  backgroundColor: 'white',
                  mx: 2,
                  left: 370,
                  padding: 10,
                  textDecoration: 'none',
                  borderRadius: 5,
                  cursor: 'pointer',  
                }}
              >
                {page.name}
              </Typography>
            ))}
          </Box>

          {/* User menu */}
          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
