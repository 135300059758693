import * as React from 'react';
import DrawerAppBar from './navbar'; 
import Banner from './banner';
import LeftImageBanner from './imageLeftBanner';
import RightImageBanner from './imageRightBanner';
import rightThirdBannerImage from '../assets/Public Awareness.png';
import BannerWithCards from './BannerWithImageCards';
import BannerWithVideoCards from './bannerWithVideoCards';
// import BannerWithArrowCards from './bannerWithArrowCards';
import Footer from './footer';
import govImage from '../assets/Gov logo.png';
import NDPImage from '../assets/NDP Logo.png';
import sanralImage from '../assets/Sanral Logo.png';
import svg30Years from '../assets/30 YEARS LOGO 2024.svg';
import backgroundImage from '../assets/Background Desktop.png'; 
import SAFlag from '../assets/Top Banner Flag.png';
import leftImageSecondBanner from '../assets/Mobilizing Image.png';

//footer images
const images = [
  govImage,
  svg30Years,
  NDPImage,
  sanralImage,
];

export default function Home() {
  return (
    <div style={{ position: 'relative',  margin: 0,
  overflowX: 'hidden' }}>
      
      {/* background image*/}
      <img
        src={backgroundImage}
        alt="Background"
        style={{
          position: 'fixed',  //was absolute now fixed
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          objectFit: 'cover', 
          zIndex: -1, 
          backgroundAttachment: 'fixed',  
        }}
      />

      {/* Navbar */}
      <DrawerAppBar />  

      {/* Top Banner */}
      <Banner
        leftImageSrc={svg30Years}
        rightImageSrc={SAFlag}
        topText="Celebrating 30 Years of Freedom and Democracy in South Africa"
        bottomText="Join the national celebration of South Africa’s <br /> journey to freedom, unity, and progress."
        id="home"
        />

      {/* Second Banner */}
      <LeftImageBanner 
        titleText="Mobilizing Collective Ownership of 30 Years of Freedom and Democracy"
        leftImageSrc={leftImageSecondBanner}
        rightText="Follow our journey of Freedom"
        navigateToUrl={true}
        // onButtonClick={() => alert('Button Clicked!')}
        id="journeyMap"
      />

      {/* Third Banner */}
      <RightImageBanner
        titleText2="Building Public Awareness and Excitement for 30 Years of Freedom and Democracy 2024"
        leftImageSrc2={rightThirdBannerImage}
        rightText2="To follow our upcoming events, view our latest calendar"
        onButtonClick2={() => alert('Button Clicked!')}
        id="calendar"
      />

      {/* Additional Banners */}
      <BannerWithCards
        titleText="30 Years of Freedom and Democracy Gallery"
        onButtonClick={() => alert('Button Clicked!')}
        id="gallery"
      />
      <BannerWithVideoCards
        titleText="30 Years of Freedom and Democracy Videos"
        onButtonClick={() => alert('Button Clicked!')}
        id="videoGallery"
      />
      {/* <BannerWithArrowCards
        titleText="30 Years of Freedom and Democracy Media Releases"
        onButtonClick={() => alert('Button Clicked!')}
        id="media-releases"  
      /> */}

      {/* Footer */}
      <Footer images={images} />
    </div>
  );
}
