import * as React from "react";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: { xs: "46%", sm: "50%", md: "50%" },
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 300, md: 400 },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EventSelectedModal({ open, handleClose, selectedEvent }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
       
        {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Sanral calendar events
        </Typography> */}
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Name: {selectedEvent.name}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          email: {selectedEvent.email}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Local municipality: {selectedEvent.localMunicipality}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Date: {selectedEvent.start_date}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Venue: {selectedEvent.venue}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Stakeholders: {selectedEvent.stakeHolders}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Activity: {selectedEvent.activity}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          college: {selectedEvent.college}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          District/Metro: {selectedEvent.districtMetro}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          End date: {selectedEvent.end_date}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Merseta: {selectedEvent.merseta}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Name: {selectedEvent.name}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Nyda: {selectedEvent.nyda}
        </Typography>
        {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Province: {selectedEvent.province}
        </Typography> */}
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Tel: {selectedEvent.tel}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Venue: {selectedEvent.venue}
        </Typography>
      </Box>
    </Modal>
  );
}
