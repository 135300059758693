import * as React from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
import telephoneIcon from '../assets/Call Icon.svg';  
import emailIcon from '../assets/Email Icon.svg';

export default function Footer({ images }) {
  return (
    <Box sx={{ backgroundColor: '#d9d7d2', py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          
          {/*desktop: first image takes larger space */}
          <Grid
            item
            xs={7} // full width for mobile. was 12
            sm={4}  // and here first image takes 4 columns on desktop
            md={3}  // and then here first image takes 3 columns on larger desktop
            sx={{
              order: { xs: 0, sm: 0 }, // but i need to make sure the first image stays at the top for mobile
              marginRight: { xs: '0', sm: '150px', md: '0' }, //shift the image to the right only for tablet (sm)

            }}
          >
            <img
              src={images[0]} 
              alt={`Footer ${0 + 1}`}  
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: '8px',
                marginLeft: '-90px',  //this wasnt here
              }}
            />
          </Grid>

          {/*so for desktop display the remaining images in a row */}
          <Grid container item xs={12} sm={8} spacing={2} justifyContent="center">
            {images.slice(1).map((image, index) => (
              <Grid
                item
                xs={2}  // and then for here for mobile, each image takes 4 columns (3 images per row)  PS this was always 4 
                sm={2}  // then here for desktop, each image takes 2 columns (5 images per row)
                md={2}  // and for larger desktops, maintain 2 images per row (probably redundant but whatever)
                key={index + 1}
              >
                <img
                  src={image} 
                  alt={`Footer Image ${index + 2}`}  
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    marginBottom: '-18px',
                    marginLeft: '-90px',   //this wasnt here
                  }}
                />
              </Grid>
            ))}

          {/*get in touch section: NOW placed after the last image */}
<Grid item xs={12} sm={4} sx={{ textAlign: 'right', display: { sm: 'block', md: 'block' } }}>
  <Box sx={{
    marginTop: { xs: -15, sm: -10, md: -2 },  //adjust the get in touch text
    textAlign: 'right'
  }}>
    <Typography variant="h6" gutterBottom>
      Get In Touch:
    </Typography>
  </Box>

  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    textAlign: 'right',
    gap: { xs: 1, sm: 2 }, //adjusts the space between the items for mobile (xs) and larger screens (sm)
    marginRight: { xs: 0, sm: -14 }, //adjusts the right margin for mobile (xs) and larger screens (sm)
    mt: { xs: 0, sm: 0 }, //moves the entire container up on mobile and desktop views this wasn`t here
  }}>
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right',
      gap: { xs: 1, sm: 2 }, //controls the space between the image and the text on mobile (xs) and larger screens (sm)
    }}>
      <img 
        src={telephoneIcon} 
        alt="Telephone" 
        style={{ width: 20, height: 20, marginRight: 8 }} 
      />
      <Typography variant="body2" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' }, marginRight: {xs: 0, sm: 12} }}>
        +123 456 789
      </Typography>
    </Box>
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right',
      mt: { xs: 1, sm: 0 }, 
      gap: { xs: 1, sm: 2 }, //controls the space between the image and the text on mobile (xs) and larger screens (sm)
    }}>
      <img 
        src={emailIcon} 
        alt="Email" 
        style={{ width: 20, height: 20 }} 
      />
      <Typography variant="body2" sx={{
        fontSize: { xs: '0.9rem', sm: '1rem' },
        marginRight: { xs: 3, sm: 15 }, // Adjusts the right margin for mobile (xs) and larger screens (sm)
        marginTop: { xs: 0, sm: -1 }, // Adds margin-top for mobile (xs) and larger screens (sm)
      }}>
        sanral.com
      </Typography>
    </Box>
  </Box>
</Grid>

          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
