import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; 
import Box from '@mui/material/Box';

export default function VideoActionAreaCard({ image, title, description, onCardClick }) {
    return (
      <Card sx={{ maxWidth: 345, width: 300, position: 'relative' }}> 
        <CardActionArea onClick={onCardClick}>
          <CardMedia
            component="img"
            height="140"
            image={image}
            alt={title}
          />
          
          {/*play icon centered over the entire card */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.4)', // this simply gives the dark overlay, remove it will remove the dark "Back ground"
            }}
          >
            <PlayArrowIcon
              sx={{
                fontSize: 50,
                color: 'white',
                opacity: 0.8,
                border: '4px solid white',  
                borderRadius: '50%',  
                padding: '8px',  //control size of the ring around the icon
              }}
            />
          </Box>

          <CardContent
            sx={{
              backgroundColor: 'rgba(12, 59, 148)',
            }}
          >
            <Typography 
              gutterBottom 
              variant="h5" 
              component="div"
              sx={{ 
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis',
                color: 'white' 
              }}
            >
              {title}  
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: 'white'
              }}
            >
              {description}  
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
}
