import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CustomButton from './customButton';
import { useTheme, useMediaQuery } from '@mui/material'; 


function LeftImageBanner({ titleText, leftImageSrc, rightText, onButtonClick, navigateToUrl = false}) {
  const theme = useTheme(); 
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  return (
    <Box sx={{  py: 4 }} id="journeyMap">
      <Container maxWidth="lg">
        {/* top centered bold title */}
        <Typography 
         variant={isMobile ? 'h6' : 'h4'}
         sx={{ fontWeight: isMobile ? 'bold' : 500,  textAlign: 'center', mb: 3 }}>
          {titleText}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >

          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
            <img
              src={leftImageSrc}
              alt="Banner left"
              style={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '8px',
              }}
            />
          </Box>

          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', textAlign: 'center' }}>
            <Box>
              <Typography variant="body1" sx={{fontWeight: 'bold', mb: 2 }}>
                {rightText}
              </Typography>
              <CustomButton 
                text="View Journey Map" 
                onClick={onButtonClick} 
                navigateToUrl={navigateToUrl}
                url="/bus-map/"
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default LeftImageBanner;
